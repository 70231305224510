// src/PrivateRoute.js

import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import api from './services/api'; // Axios instance with interceptor
import { toast } from 'react-toastify';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (currentUser) {
        try {
          // Axios interceptor adds the Authorization header
          const response = await api.get('/check-subscription');

          console.log('PrivateRoute Subscription status response:', response.data);

          if (response.data && typeof response.data.isSubscribed === 'boolean') {
            setIsSubscribed(response.data.isSubscribed);
          } else {
            console.error('Invalid subscription status response:', response.data);
            setIsSubscribed(false);
          }
        } catch (error) {
          if (error.response) {
            // Server responded with a status other than 2xx
            console.error('Error checking subscription status in PrivateRoute:', error.response.data);
          } else if (error.request) {
            // Request was made but no response received
            console.error('No response received in PrivateRoute:', error.request);
          } else {
            // Something else happened
            console.error('Error in PrivateRoute:', error.message);
          }
          setIsSubscribed(false); // Default to unsubscribed on error
          toast.error('Failed to verify subscription status.');
        }
      } else {
        setIsSubscribed(false);
      }
      setLoading(false);
    };

    fetchSubscriptionStatus();
  }, [currentUser]);

  if (loading) {
    // You can return a loading spinner or placeholder here
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    // User is not authenticated
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isSubscribed) {
    // User is authenticated but not subscribed
    return <Navigate to="/subscribe" state={{ from: location }} replace />;
  }

  // User is authenticated and subscribed
  return children;
};

export default PrivateRoute;
