// src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Ensure correct path
import { getAuth } from 'firebase/auth';
import api from '../services/api'; // Axios instance with interceptor
import './Navbar.css'; // Import your Navbar CSS
import { logEvent } from '../firebase'; // Ensure correct implementation
import { toast } from 'react-toastify';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const auth = getAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (currentUser) {
        try {
          // No need to manually add Authorization headers; interceptor handles it
          const response = await api.get('/check-subscription');

          console.log('Subscription status response:', response.data);

          if (response.data && typeof response.data.isSubscribed === 'boolean') {
            setIsSubscribed(response.data.isSubscribed);
          } else {
            console.error('Invalid subscription status response:', response.data);
            setIsSubscribed(false); // Default to not subscribed on invalid response
          }
        } catch (error) {
          console.error('Error checking subscription status:', error);
          setIsSubscribed(false); // Default to not subscribed on error
          toast.error('Failed to check subscription status.');
        }
      } else {
        setIsSubscribed(false); // User not logged in
      }
      setLoading(false);
    };

    fetchSubscriptionStatus();
  }, [currentUser]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle('menu-open', !isMenuOpen);
  };

  const handleLogout = async () => {
    if (currentUser) {
      try {
        await auth.signOut();
        setIsSubscribed(false); // Reset subscription status on logout
        logEvent('logout', { uid: currentUser.uid });
        toast.success('Logged out successfully.');
        navigate('/'); // Redirect to home after logout
      } catch (error) {
        console.error('Error logging out:', error);
        toast.error('Failed to log out.');
      }
    }
  };

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-brand">
        .Generate()
      </Link>
      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <Link
          to="/"
          className="nav-item"
          onClick={() => {
            setIsMenuOpen(false);
            logEvent('navigation', { destination: 'resources' });
          }}
        >
          RESOURCES
        </Link>
        <Link
          to="/chart"
          className="nav-item"
          onClick={() => {
            setIsMenuOpen(false);
            logEvent('navigation', { destination: 'launch_chart' });
          }}
        >
          LAUNCH CHART
        </Link>
        <Link
          to="/contact"
          className="nav-item"
          onClick={() => {
            setIsMenuOpen(false);
            logEvent('navigation', { destination: 'contact' });
          }}
        >
          CONTACT
        </Link>

        {currentUser ? (
          <>
            {/* Hide Dashboard link when on Dashboard page */}
            {location.pathname !== '/dashboard' && (
              <Link
                to="/dashboard"
                className="nav-item"
                onClick={() => {
                  setIsMenuOpen(false);
                  logEvent('navigation', { destination: 'dashboard' });
                }}
              >
                DASHBOARD
              </Link>
            )}

            {/* Show SUBSCRIBE button only if not subscribed and not on Subscribe page */}
            {!loading && !isSubscribed && location.pathname !== '/subscribe' && (
              <Link
                to="/subscribe"
                className="nav-item subscribe-button"
                onClick={() => {
                  setIsMenuOpen(false);
                  logEvent('navigation', { destination: 'subscribe' });
                }}
              >
                SUBSCRIBE
              </Link>
            )}

            <button
              className="nav-item logout-button"
              onClick={() => {
                setIsMenuOpen(false);
                handleLogout();
                logEvent('navigation', { destination: 'logout' });
              }}
            >
              LOG OUT
            </button>
          </>
        ) : (
          <>
            <Link
              to="/login"
              className="nav-item login-button"
              onClick={() => {
                setIsMenuOpen(false);
                logEvent('navigation', { destination: 'login' });
              }}
            >
              LOG IN
            </Link>
            <Link
              to="/sign-up"
              className="nav-item signup-button"
              onClick={() => {
                setIsMenuOpen(false);
                logEvent('navigation', { destination: 'sign_up' });
              }}
            >
              SIGN UP
            </Link>
          </>
        )}
      </div>
      <div
        className={`menu-toggle ${isMenuOpen ? 'open' : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Navbar;
