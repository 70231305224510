import React, { useEffect, useState } from 'react';
import './HeroSection.css';

const HeroSection = () => {
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  const words = [".Generate()", "Foundation Model", "Forecasts", "Better Informed Decisions", "Co-Pilot", "For Crypto Markets"];
  const typingSpeed = 100;
  const deletingSpeed = 50;
  const pauseDuration = 1000;

  useEffect(() => {
    const handleTyping = () => {
      const currentWord = words[wordIndex];
      if (isDeleting) {
        if (charIndex > 0) {
          setDisplayText(currentWord.substring(0, charIndex - 1));
          setCharIndex(charIndex - 1);
        } else {
          setIsDeleting(false);
          setWordIndex((wordIndex + 1) % words.length);
        }
      } else {
        if (charIndex < currentWord.length) {
          setDisplayText(currentWord.substring(0, charIndex + 1));
          setCharIndex(charIndex + 1);
        } else {
          setTimeout(() => setIsDeleting(true), pauseDuration);
        }
      }
    };

    const typingTimeout = setTimeout(handleTyping, isDeleting ? deletingSpeed : typingSpeed);

    return () => clearTimeout(typingTimeout);
  }, [charIndex, isDeleting, wordIndex]);

  return (
    <div className="hero-container">
      <div className="gradient"></div>
      <div className="hero-content">
        <h1 className="hero-title">
          <span>{displayText}</span><span className="cursor">_</span><br />Market Foundation Model
        </h1>
        <a href="/chart" className="button-primary">Explore Predictions</a>
      </div>
    </div>
  );
};

export default HeroSection;
