import React, { useEffect } from 'react';
import './PrivacyNoticePage.css';
import { Link } from 'react-router-dom';
import { logEvent } from './analytics';
import Navbar from './components/Navbar'; // Import the Navbar component

const PrivacyNoticePage = () => {
  useEffect(() => {
    logEvent('PageView', 'PrivacyNoticePage'); // Log page view when the component is mounted
  }, []);

  return (
    <div>
      <Navbar /> {/* Add the Navbar component here */}
      <div className="privacy-notice-page">
        <h1>Privacy Notice</h1>
        <div className="privacy-notice">
          <p>Last Updated: 26th of June 2024</p>
          <p><strong>Introduction</strong></p>
          <p>Your privacy is of utmost importance to us at Miliscorp. This Privacy Notice outlines the types of personal data we collect, how we process and protect it, and your rights regarding your personal information when you visit our website www.dotgenerate.com (“Site”). Please review this notice carefully, and feel free to contact us with any questions or concerns.</p>
          <p><strong>1. Data We Collect</strong></p>
          <p>When you visit our Site, we may collect and process the following categories of personal data:</p>
          <ul>
            <li>Information Provided Voluntarily: This includes personal data you provide through contact forms, newsletter subscriptions, or other means of communication such as your name, email address, and any other information you choose to share with us.</li>
            <li>Browsing Data: We automatically collect information about your device and your interaction with our Site using technologies like cookies. This may include your IP address, browser type, operating system, pages viewed, and the duration of your visit.</li>
          </ul>
          <p><strong>2. How We Use Your Data</strong></p>
          <ul>
            <li>Service Provision: To provide and maintain the services you request, and to respond to your inquiries.</li>
            <li>Personalization: To tailor your experience on our Site according to your preferences and to enhance the functionality of the Site.</li>
            <li>Marketing Communications: To send you promotional materials, subject to obtaining your consent.</li>
            <li>Analytical Purposes: To analyze and understand how our Site is used, and to gather statistical data for improving our services.</li>
          </ul>
          <p><strong>3. Data Sharing</strong></p>
          <ul>
            <li>Service Providers: We may disclose your information to service providers who assist us in operating our Site and delivering services.</li>
            <li>Legal Obligations: We may share your data when required by law or to protect our legal rights.</li>
            <li>With Your Consent: We will share your data with other third parties only if you have given us explicit consent to do so.</li>
          </ul>
          <p><strong>4. Data Controller</strong></p>
          <p>The data controller responsible for the collection and use of your personal data on this Site is:</p>
          <p>Miliscorp<br />
            Email: jakub.milis@miliscorp.cz<br />
            Postal Address: Grafická 831/20, Smíchov, 150 00 Praha 5</p>
          <p><strong>5. Data Retention</strong></p>
          <p>We retain your personal data only as long as necessary to fulfill the purposes for which it was collected, and in compliance with applicable laws.</p>
          <p><strong>6. Your Rights</strong></p>
          <ul>
            <li>Access: You can request access to your personal data we hold.</li>
            <li>Rectification: You have the right to correct any inaccurate or incomplete data.</li>
            <li>Deletion: You may request the deletion of your personal data.</li>
            <li>Restriction: You can request the restriction of processing under certain conditions.</li>
          </ul>
          <p>To exercise these rights, please contact us using the details provided below.</p>
          <p><strong>7. Changes to This Privacy Notice</strong></p>
          <p>We may update this Privacy Notice from time to time. We encourage you to review this page periodically to stay informed about how we protect your data.</p>
          <p><strong>8. Contact Us</strong></p>
          <p>If you have any questions or concerns regarding this Privacy Notice or our data practices, please contact us at:</p>
          <p>Email: jakub.milis@miliscorp.cz<br />
            Postal Address: Grafická 831/20, Smíchov, 150 00 Praha 5</p>
          <p>Thank you for reading this privacy policy.</p>
          <p>Sincerely yours,<br />Dotgenerate.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyNoticePage;
