// src/App.js

import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import LandingPage from './LandingPage';
import ProductPage from './ProductPage';
import ContactPage from './ContactPage';
import PrivacyNoticePage from './PrivacyNoticePage';
import LegalDisclaimerPage from './LegalDisclaimerPage';
import LoginPage from './LoginPage';
import SignUpPage from './SignUpPage';
import Dashboard from './Dashboard';
import ResetPassword from './components/ResetPassword';
import SubscribePage from './SubscribePage';
import ManageSubscription from './ManageSubscription'; // Newly added component
import NotFoundPage from './components/NotFoundPage'; // Ensure this is imported
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import { initializeAnalytics, logPageView } from './analytics';
import './App.css'; // Ensure global styles are imported
import Layout from './components/Layout'; // Import the Layout component
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

const App = () => {
  useEffect(() => {
    initializeAnalytics();
  }, []);

  return (
    <AuthProvider>
      <Router>
        <PageTracking />
        <Routes>
          {/* Routes Wrapped with Layout (includes Navbar) */}
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage />} />

            <Route path="contact" element={<ContactPage />} />
            <Route path="privacy-notice" element={<PrivacyNoticePage />} />
            <Route path="legal-disclaimer" element={<LegalDisclaimerPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="reset-password" element={<ResetPassword />} />

            {/* Dashboard is protected and requires authentication */}
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            {/* Manage Subscription Page */}
            <Route
              path="manage-subscription"
              element={
                <PrivateRoute>
                  <ManageSubscription />
                </PrivateRoute>
              }
            />

            {/* Subscribe Page */}
            <Route path="subscribe" element={<SubscribePage />} />

            {/* Catch-all route for undefined paths */}
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          {/* Routes NOT Wrapped with Layout (excludes Navbar) */}
          
          {/* Redirect /chart to /chart/BTCUSDT */}
          <Route
            path="/chart"
            element={
              <PrivateRoute>
                <Navigate to="/chart/BTCUSDT" replace />
              </PrivateRoute>
            }
          />

          {/* Product Page - Excludes Navbar */}
          <Route
            path="/chart/:pair"
            element={
              <PrivateRoute>
                <ProductPage />
              </PrivateRoute>
            }
          />
        </Routes>
        <ToastContainer />
      </Router>
    </AuthProvider>
  );
};

// Page tracking for analytics
const PageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default App;
