import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from './firebase'; // Import auth from your Firebase setup
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import './SignUpPage.css';
import Navbar from './components/Navbar'; // Import the Navbar component

const SignUpPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      // Set success message
      setMessage('Sign-up successful! Verification email sent. Please check your inbox.');
      setError('');

      // Redirect to login page after 5 seconds
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (error) {
      // Handle different Firebase Auth errors
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('The email address is already in use by another account.');
          break;
        case 'auth/invalid-email':
          setError('The email address is not valid.');
          break;
        case 'auth/weak-password':
          setError('The password is too weak. Please use a stronger password.');
          break;
        default:
          setError(error.message); // Handle other errors
      }
      setMessage('');
    }
  };

  return (
    <div className="signup-page">
      <Navbar /> {/* Use the Navbar component */}

      <div className="signup-container">
        <div className="signup-box">
          <h2>Sign Up</h2>
          <form onSubmit={handleSignUp}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button type="submit">Sign Up</button>
          </form>
          {message && <p className="message">{message}</p>}
          {error && <p className="error">{error}</p>}
          <p>
            <Link to="/login">Already have an account? Login here</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
