// src/analytics.js
import { analytics, logEvent as firebaseLogEvent } from './firebase';

export const initializeAnalytics = () => {
  console.log('Initializing Firebase Analytics');
  // Firebase Analytics is automatically initialized with the Firebase app
};

export const logPageView = (page) => {
  console.log('Logging page view for:', page);
  firebaseLogEvent('page_view', { page_path: page });
};

export const logEvent = (category, action) => {
  console.log(`Logging event - Category: ${category}, Action: ${action}`);
  firebaseLogEvent(action, { event_category: category });
};
