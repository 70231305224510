// src/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext'; // Ensure AuthContext is correctly implemented
import { useNavigate, Link, useLocation } from 'react-router-dom';
import api from './services/api'; // Axios instance
import './Dashboard.css';
import { logEvent } from './firebase'; // Ensure this is correctly implemented
import { getAuth } from 'firebase/auth'; // Import getAuth from Firebase
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar'; // Import the Navbar component

const Dashboard = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState('inactive'); // 'active' or 'canceled'
  const [renewalDate, setRenewalDate] = useState(null); // Date when subscription renews
  const [endDate, setEndDate] = useState(null); // Date when canceled subscription ends
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth(); // Initialize auth

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          // Fetch user data from Firestore via backend API
          const response = await api.get('/get-user-data', {
            headers: {
              Authorization: `Bearer ${await currentUser.getIdToken()}`,
            },
          });

          const { subscription } = response.data;

          if (subscription) {
            if (subscription.status === 'active' || subscription.status === 'trialing') {
              setSubscriptionStatus('active');
              setRenewalDate(subscription.current_period_end);
              setEndDate(null);
            } else if (subscription.status === 'canceled') {
              setSubscriptionStatus('canceled');
              setEndDate(subscription.current_period_end);
              setRenewalDate(null);
            } else {
              setSubscriptionStatus('inactive');
              setRenewalDate(null);
              setEndDate(null);
            }
          } else {
            setSubscriptionStatus('inactive');
            setRenewalDate(null);
            setEndDate(null);
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
          toast.error('Failed to fetch subscription data.');
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');

    if (sessionId) {
      // Start polling to verify subscription status
      startPolling(sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const startPolling = (sessionId) => {
    const pollSubscriptionStatus = async () => {
      try {
        const response = await api.get(`/api/check-subscription?session_id=${sessionId}`, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`,
          },
        });

        if (response.data.isSubscribed) {
          setSubscriptionStatus('active');
          setRenewalDate(response.data.subscription.current_period_end);
          setEndDate(null);
          toast.success('Subscription confirmed! Welcome to your dashboard.');
          logEvent('subscription_confirmed', { uid: currentUser.uid });
          clearInterval(pollingIntervalRef.current);
          clearTimeout(pollingTimeoutRef.current);
        }
      } catch (err) {
        console.error('Error polling subscription status:', err);
        // Optionally handle specific errors
      }
    };

    // Start polling every 3 seconds
    const pollingInterval = setInterval(pollSubscriptionStatus, 3000);

    // Stop polling after 60 seconds
    const pollingTimeout = setTimeout(() => {
      clearInterval(pollingInterval);
      if (subscriptionStatus !== 'active') {
        toast.error('Subscription confirmation timed out. Please refresh the page.');
        logEvent('subscription_timeout', { uid: currentUser.uid });
        navigate('/subscribe');
      }
    }, 60000); // 60 seconds

    // Store references to clear them later
    pollingIntervalRef.current = pollingInterval;
    pollingTimeoutRef.current = pollingTimeout;
  };

  const pollingIntervalRef = React.useRef(null);
  const pollingTimeoutRef = React.useRef(null);

  const handleCancelSubscription = async () => {
    try {
      const token = await currentUser.getIdToken(true);
      const response = await api.post(
        '/api/cancel-subscription',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSubscriptionStatus('canceled');
        setEndDate(response.data.subscription.current_period_end);
        setRenewalDate(null);
        toast.info('Subscription will be canceled at the end of the current period.');
        logEvent('subscription_canceled', { uid: currentUser.uid });
      } else {
        toast.error('Failed to cancel subscription.');
      }
    } catch (err) {
      console.error('Error canceling subscription:', err);
      toast.error('Failed to cancel subscription.');
    }
  };

  const handleLogout = async () => {
    if (currentUser) {
      try {
        await auth.signOut();
        logEvent('logout', { uid: currentUser.uid });
        toast.success('Logged out successfully.');
        navigate('/'); // Redirect to home after logout
      } catch (error) {
        console.error('Error logging out:', error);
        toast.error('Failed to log out.');
      }
    }
  };

  return (
    <div className="dashboard-page">
      <Navbar /> {/* Imported Navbar */}

      {/* Dashboard Content */}
      <div className="dashboard-container">
        <div className="dashboard-box">
          <h2>Dashboard</h2>
          {currentUser ? (
            <div className="subscription-info">
              <p>
                <strong>Email:</strong> {currentUser.email}
              </p>
              <p>
                <strong>Subscription Status:</strong>{' '}
                {subscriptionStatus.charAt(0).toUpperCase() + subscriptionStatus.slice(1)}
              </p>

              {/* Display Renewal Date if subscription is active */}
              {subscriptionStatus === 'active' && renewalDate && (
                <p>
                  <strong>Next Renewal Date:</strong>{' '}
                  {new Date(renewalDate * 1000).toLocaleDateString()}
                </p>
              )}

              {/* Display End Date if subscription is canceled */}
              {subscriptionStatus === 'canceled' && endDate && (
                <p>
                  <strong>Subscription Ends On:</strong>{' '}
                  {new Date(endDate * 1000).toLocaleDateString()}
                </p>
              )}

              {/* Cancel Subscription Button */}
              {subscriptionStatus === 'active' && (
                <button
                  className="cancel-subscription-button"
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </button>
              )}

              {/* Informational Messages */}
              {subscriptionStatus === 'canceled' && (
                <p className="info-message">
                  Your subscription has been canceled and will end on{' '}
                  {new Date(endDate * 1000).toLocaleDateString()}.
                </p>
              )}

              {subscriptionStatus === 'inactive' && (
                <p className="info-message">
                  You do not have an active subscription. Please subscribe to access premium features.
                </p>
              )}
            </div>
          ) : (
            <p>Please log in to view your dashboard.</p>
          )}
        </div>
      </div>

      {/* Toast Notifications */}
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
