// src/ManageSubscription.js

import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { auth, db } from './firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import './ManageSubscription.css';
import { logEvent } from './firebase';

const ManageSubscription = () => {
  const { currentUser } = useAuth();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // e.g., 'active', 'canceled'
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setSubscriptionStatus(userData.subscriptionStatus || 'inactive'); // Assuming 'subscriptionStatus' field
        }
      }
      setLoading(false);
    };

    fetchSubscriptionStatus();
  }, [currentUser]);

  const handleCancelSubscription = async () => {
    try {
      // Call the backend to cancel the subscription
      const response = await fetch('/api/cancel-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await currentUser.getIdToken()}`,
        },
        body: JSON.stringify({}),
      });

      const data = await response.json();

      if (response.ok) {
        setSubscriptionStatus('canceled');
        setMessage(data.message || 'Subscription canceled successfully.');
        setError('');
        logEvent('subscription_canceled', { uid: currentUser.uid });
      } else {
        throw new Error(data.error || 'Failed to cancel subscription.');
      }
    } catch (error) {
      setError(error.message);
      setMessage('');
    }
  };

  const handleGoToCustomerPortal = async () => {
    try {
      const response = await fetch('/api/create-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await currentUser.getIdToken()}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.url; // Redirect to Stripe Customer Portal
        logEvent('redirect_to_portal', { uid: currentUser.uid });
      } else {
        throw new Error(data.error || 'Failed to create portal session.');
      }
    } catch (error) {
      setError(error.message);
      setMessage('');
    }
  };

  if (loading) {
    return <div className="manage-subscription-page"><p>Loading...</p></div>;
  }

  if (!currentUser) {
    return (
      <div className="manage-subscription-page">
        <p>You need to be logged in to manage your subscription.</p>
        <Link to="/login" className="button-primary">Log In</Link>
      </div>
    );
  }

  return (
    <div className="manage-subscription-page">
      <h2>Manage Subscription</h2>
      <p><strong>Current Status:</strong> {subscriptionStatus.charAt(0).toUpperCase() + subscriptionStatus.slice(1)}</p>

      {subscriptionStatus === 'active' ? (
        <div className="subscription-actions">
          <button onClick={handleGoToCustomerPortal} className="portal-button">
            Manage Your Subscription
          </button>
          <button onClick={handleCancelSubscription} className="cancel-button">
            Cancel Subscription
          </button>
        </div>
      ) : (
        <p>You do not have an active subscription.</p>
      )}

      {message && <p className="message">{message}</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default ManageSubscription;
