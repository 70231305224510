// src/LandingPage.js

import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { db, logEvent } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import Navbar from './components/Navbar';
import FAQ from './components/FAQ';
import HeroSection from './components/HeroSection'; // Import the HeroSection component
import DemoChart from './components/DemoChart'; // Import the DemoChart component
import './LandingPage.css';
import './components/Navbar.css';
import imageChart1 from './assets/image_chart1.png';
import imageChart2 from './assets/chart2.png';
import xIcon from './assets/logo-white.png';

const LandingPage = () => {
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [message, setMessage] = useState('');

  const words = [".GENERATE()", "FOUNDATION MODEL", "FORECASTS", "BETTER INFORMED DECISIONS", "YOUR CO-PILOT", "FOR CRYPTO MARKETS"];
  const typingSpeed = 35;
  const deletingSpeed = 30;
  const pauseDuration = 500;

  const isMounted = useRef(true);

  useEffect(() => {
    logEvent('page_view', { page_path: 'LandingPage' });

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const handleTyping = () => {
      if (!isMounted.current) return;

      const currentWord = words[wordIndex];
      if (isDeleting) {
        if (charIndex > 0) {
          setDisplayText(currentWord.substring(0, charIndex - 1));
          setCharIndex(charIndex - 1);
        } else {
          setIsDeleting(false);
          setWordIndex((wordIndex + 1) % words.length);
        }
      } else {
        if (charIndex < currentWord.length) {
          setDisplayText(currentWord.substring(0, charIndex + 1));
          setCharIndex(charIndex + 1);
        } else {
          setTimeout(() => setIsDeleting(true), pauseDuration);
        }
      }
    };

    const typingTimeout = setTimeout(handleTyping, isDeleting ? deletingSpeed : typingSpeed);

    return () => clearTimeout(typingTimeout);
  }, [charIndex, isDeleting, wordIndex]);

  const handleNewsletterSignUp = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      if (!newsletterEmail) {
        setMessage('Please enter a valid email address.');
        return;
      }

      await addDoc(collection(db, 'newsletter'), { email: newsletterEmail });
      setMessage('Thank you for signing up for our newsletter!');
      setNewsletterEmail('');
      logEvent('sign_up', { method: 'newsletter' });
    } catch (error) {
      setMessage('Error signing up for newsletter: ' + error.message);
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="landing-page">
      <Navbar />
      <HeroSection displayText={displayText} /> {/* Include the HeroSection component */}
      
      {/* **Include the DemoChart Component Here** */}
      <div className="demo-chart-section">
        <h2>Demo Chart</h2>
        <DemoChart />
      </div>

      <div className="content">
        <h2>Foundation Model Based Data Predictions</h2>
        <p>Detect market regimes with our Bands segmentation</p>
        <div>
          <img src={imageChart1} alt="Descriptive Alt Text" className="responsive-image" />
          <p>Get insight into what might come next with our Large Predictive Model generated Heatmap</p>
          <img src={imageChart2} alt="Descriptive Alt Text" className="responsive-image" />
        </div>
        <Link to="/chart" onClick={() => logEvent('navigation', { destination: 'chart' })}>Explore Predictions</Link>
      </div>
      
      <footer className="footer">
        <div className="footer-container">
          <div className="newsletter-section">
            <h3>Join to get <br /><span>our latest news</span></h3>
            <form onSubmit={handleNewsletterSignUp}>
              <input
                type="email"
                value={newsletterEmail}
                onChange={(e) => setNewsletterEmail(e.target.value)}
                placeholder="Email address"
                required
              />
              <button type="submit">Join now</button>
            </form>
            {message && <p className="newsletter-message">{message}</p>}
          </div>
          <div className="footer-content">
            <div className="footer-about">
              <h3>.Generate()</h3>
              <p>.Generate() is a platform powered by AI foundation model predicting next data points in financial time series.</p>
              <div className="social-icons">
                <a href="https://twitter.com/yourtwitterhandle" target="_blank" rel="noopener noreferrer">
                  <img src={xIcon} alt="X" />
                </a>
              </div>
            </div>
            <div className="footer-links">
              <div className="footer-column">
                <h4>Product</h4>
                <Link to="/chart">Chart</Link>
              </div>
              <div className="footer-column">
                <h4>Company</h4>
                <Link to="/contact">About</Link>
              </div>
              <div className="footer-column">
                <h4>Resources</h4>
                <Link to="/docs">Docs</Link>
                <Link to="/legal-disclaimer">Legal Disclaimer</Link>
                <Link to="/privacy-notice">Privacy Notice</Link>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>©2024 Generate. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
