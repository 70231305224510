// src/components/Layout.js

import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar'; // Ensure the correct path
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Layout.css'; // Optional: Add Layout-specific styles

const Layout = () => {
  return (
    <>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <ToastContainer /> {/* Toast notifications are available across all pages */}
    </>
  );
};

export default Layout;
