// src/components/DemoChart.js

import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import demoOHLCData from '../demoData/demo_ohlc_data.json';
import demoHeatmapData from '../demoData/demo_heatmap_data.json';
import demoBandData from '../demoData/demo_band_data.json';
// Removed demo_midline_data.json import
import { HLCAreaSeries } from '../hlc-area-series/hlc-area-series';
import { HeatMapSeries } from '../heatmap-series/heatmap-series';
import './DemoChart.css';

const DemoChart = () => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const candleSeriesRef = useRef(null);
  const heatMapSeriesRef = useRef(null);
  const customSeriesRefLightSalmon = useRef(null);
  const customSeriesRefCyan = useRef(null);
  const customSeriesRefTeal = useRef(null);
  const customSeriesRefUpper = useRef(null);
  // Removed smoothedMidlineSeriesRef

  const maxHeatmapAmountRef = useRef(100); // Adjust based on your demo data

  // **Color Interpolation and Heatmap Shader Functions**
  const interpolateColor = (color1, color2, factor) => {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  };

  const colorStops = [
    { value: 0, color: [50, 50, 50] },       // Dark Gray
    { value: 6, color: [255, 105, 180] },  // Hot Pink
    { value: 10, color: [255, 165, 0] },    // Orange
    { value: 14, color: [255, 215, 0] },    // Gold
    { value: 16, color: [0, 255, 127] },    // Spring Green
    { value: 22, color: [0, 191, 255] },    // Deep Sky Blue
    { value: 60, color: [138, 43, 226] },   // Blue Violet
    { value: 70, color: [75, 0, 130] },     // Indigo
  ];

  const getHeatmapColor = (amount) => {
    const maxAmount = maxHeatmapAmountRef.current;
    if (maxAmount === 0) return `rgba(${colorStops[0].color.join(',')}, 0.2)`;
    
    const ratio = (amount / maxAmount) * 100; // Normalize to 0-100
    let lowerStop = colorStops[0];
    let upperStop = colorStops[colorStops.length - 1];
    
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (ratio >= colorStops[i].value && ratio <= colorStops[i + 1].value) {
        lowerStop = colorStops[i];
        upperStop = colorStops[i + 1];
        break;
      }
    }
    
    const range = upperStop.value - lowerStop.value;
    const factor = range === 0 ? 0 : (ratio - lowerStop.value) / range;
    const interpolatedColor = interpolateColor(lowerStop.color, upperStop.color, factor);
    
    const alpha = 0.6; // Adjust transparency
    return `rgba(${interpolatedColor.join(',')}, ${alpha})`;
  };

  const cellShader = (amount) => {
    return getHeatmapColor(amount);
  };

  useEffect(() => {
    // Debugging: Log imported data
    console.log('demoOHLCData:', demoOHLCData);
    console.log('demoHeatmapData:', demoHeatmapData);
    console.log('demoBandData:', demoBandData);

    if (chartContainerRef.current) {
      // Create Chart
      chartRef.current = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: 300, // Smaller height for demo chart
        layout: {
          background: {
            color: '#ffffff',
          },
          textColor: '#333333',
        },
        grid: {
          vertLines: {
            color: '#e0e0e0',
          },
          horzLines: {
            color: '#e0e0e0',
          },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
        },
        priceScale: {
          tickLabelFontSize: 12,
          tickLabelColor: '#333333',
        },
      });

      // Initialize HeatMapSeries
      const customHeatMapSeries = new HeatMapSeries();
      heatMapSeriesRef.current = chartRef.current.addCustomSeries(customHeatMapSeries, {
        cellShader,
        cellBorderWidth: 0,
        cellBorderColor: 'transparent',
      });

      // Debugging: Check heatmap data before setting
      if (demoHeatmapData) {
        console.log('Setting heatmap data:', demoHeatmapData);
        heatMapSeriesRef.current.setData(demoHeatmapData);
      } else {
        console.error('demoHeatmapData is undefined');
      }

      // Initialize Candlestick Series
      candleSeriesRef.current = chartRef.current.addCandlestickSeries({
        upColor: '#39FF14', // Neon Green
        downColor: '#FF073A', // Neon Red
        borderUpColor: '#4a4a4a', // Dark Gray border for up candles
        borderDownColor: '#4a4a4a', // Dark Gray border for down candles
        wickUpColor: '#39FF14', // Neon Green wick
        wickDownColor: '#FF073A', // Neon Red wick
        priceFormat: {
          type: 'price',
          precision: 2,
          minMove: 0.01,
        },
      });

      // Debugging: Check OHLC data before setting
      if (demoOHLCData) {
        console.log('Setting OHLC data:', demoOHLCData);
        candleSeriesRef.current.setData(demoOHLCData);
      } else {
        console.error('demoOHLCData is undefined');
      }

      // Initialize Band Series - Light Salmon
      const customSeriesViewLightSalmon = new HLCAreaSeries();
      customSeriesRefLightSalmon.current = chartRef.current.addCustomSeries(
        customSeriesViewLightSalmon,
        {
          highLineColor: '#FFA07A', // Light Salmon
          lowLineColor: '#FFA07A',
          closeLineColor: '#FFA07A',
          areaBottomColor: 'rgba(255, 160, 122, 0.5)',
          areaTopColor: 'rgba(255, 160, 122, 0.5)',
          highLineWidth: 2,
          lowLineWidth: 2,
          closeLineWidth: 2,
        }
      );

      // Debugging: Check Light Salmon band data before setting
      if (demoBandData.lightSalmon) {
        console.log('Setting Light Salmon band data:', demoBandData.lightSalmon);
        customSeriesRefLightSalmon.current.setData(demoBandData.lightSalmon);
      } else {
        console.error('demoBandData.lightSalmon is undefined');
      }

      // Initialize Band Series - Cyan
      const customSeriesViewCyan = new HLCAreaSeries();
      customSeriesRefCyan.current = chartRef.current.addCustomSeries(
        customSeriesViewCyan,
        {
          highLineColor: '#00BFFF', // Deep Sky Blue
          lowLineColor: '#00BFFF',
          closeLineColor: '#00BFFF',
          areaBottomColor: 'rgba(0, 191, 255, 0.5)',
          areaTopColor: 'rgba(0, 191, 255, 0.5)',
          highLineWidth: 2,
          lowLineWidth: 2,
          closeLineWidth: 2,
        }
      );

      // Debugging: Check Cyan band data before setting
      if (demoBandData.cyan) {
        console.log('Setting Cyan band data:', demoBandData.cyan);
        customSeriesRefCyan.current.setData(demoBandData.cyan);
      } else {
        console.error('demoBandData.cyan is undefined');
      }

      // Initialize Band Series - Teal
      const customSeriesViewTeal = new HLCAreaSeries();
      customSeriesRefTeal.current = chartRef.current.addCustomSeries(
        customSeriesViewTeal,
        {
          highLineColor: '#A9A9A9', // Dark Gray
          lowLineColor: '#A9A9A9',
          closeLineColor: '#A9A9A9',
          areaBottomColor: 'rgba(0, 255, 204, 0.5)',
          areaTopColor: 'rgba(0, 255, 204, 0.5)',
          highLineWidth: 2,
          lowLineWidth: 2,
          closeLineWidth: 2,
        }
      );

      // Debugging: Check Teal band data before setting
      if (demoBandData.lower) {
        console.log('Setting Teal band data:', demoBandData.lower);
        customSeriesRefTeal.current.setData(demoBandData.lower);
      } else {
        console.error('demoBandData.lower is undefined');
      }

      // Initialize Band Series - Upper
      const customSeriesViewUpper = new HLCAreaSeries();
      customSeriesRefUpper.current = chartRef.current.addCustomSeries(
        customSeriesViewUpper,
        {
          highLineColor: '#FF4500', // OrangeRed
          lowLineColor: '#FF4500',
          closeLineColor: 'rgba(255, 69, 0, 0.5)',
          areaBottomColor: 'rgba(255, 69, 0, 0.5)',
          areaTopColor: 'rgba(255, 69, 0, 0.5)',
          highLineWidth: 2,
          lowLineWidth: 2,
          closeLineWidth: 2,
        }
      );

      // Debugging: Check Upper band data before setting
      if (demoBandData.upper) {
        console.log('Setting Upper band data:', demoBandData.upper);
        customSeriesRefUpper.current.setData(demoBandData.upper);
      } else {
        console.error('demoBandData.upper is undefined');
      }

      // **Removed Smoothed Midline Series**

      // Optional: Adjust Heatmap maxAmount based on demo data
      if (demoHeatmapData && Array.isArray(demoHeatmapData)) {
        const incomingMax = Math.max(
          ...demoHeatmapData.flatMap(entry => entry.cells.map(cell => cell.count)),
          maxHeatmapAmountRef.current
        );
        maxHeatmapAmountRef.current = incomingMax;
        console.log('Updated maxHeatmapAmountRef:', maxHeatmapAmountRef.current);
      } else {
        console.error('demoHeatmapData is not an array or undefined');
      }
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, []);

  return (
    <div className="demo-chart-container">
      <div ref={chartContainerRef} className="demo-chart" />
    </div>
  );
};

export default DemoChart;
