// src/ProductPage.js

import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import api from './services/api'; // Custom Axios instance with baseURL and Authorization header
import Papa from 'papaparse';
import './ProductPage.css';
import { HLCAreaSeries } from './hlc-area-series/hlc-area-series'; // Custom HLC Area Series
import { Link, useParams, useNavigate } from 'react-router-dom';
import { logEvent } from './firebase'; // Firebase logging for analytics
import { useAuth } from './AuthContext'; // Auth context to access currentUser
import { FaToggleOn, FaToggleOff } from 'react-icons/fa'; // Importing additional React Icons

// **Heatmap Addition: Import HeatMapSeries as a named export**
import { HeatMapSeries } from './heatmap-series/heatmap-series'; // Adjust the path as necessary

const ProductPage = () => {
  // **Refs for Chart and Series**
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const candleSeriesRef = useRef(null);
  const smoothedMidlineSeriesRef = useRef(null);
  const customSeriesRefLightSalmon = useRef(null);
  const customSeriesRefCyan = useRef(null);
  const customSeriesRefTeal = useRef(null);
  const customSeriesRefUpper = useRef(null);
  
  // **Heatmap Addition: Ref for HeatMapSeries**
  const heatMapSeriesRef = useRef(null);
  const maxHeatmapAmountRef = useRef(20); // **Initialize to 20 since counts don't exceed 20**

  // **WebSocket Ref and Data Queues**
  const wsRef = useRef(null);
  const lastCandleRef = useRef(null);
  const lastCandleUpdateRef = useRef(null);
  const bandDataQueue = useRef([]);

  // **State Variables**
  const [isLoading, setIsLoading] = useState(true);
  const [heatmapData, setHeatmapData] = useState([]); // **Initialize as empty array**

  const historicalBandData = useRef({
    lightSalmon: [],
    cyan: [],
    lower: [],
    upper: [],
  });

  const { pair } = useParams(); // Get the trading pair from the URL
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState(pair || 'BTCUSDT');

  const tradingPairs = ['BTCUSDT', 'ETHUSDT', 'DOGEUSDT']; // List of supported trading pairs

  // Access currentUser from AuthContext
  const { currentUser } = useAuth();

  // **State Variables for Toggles**
  const [showBandData, setShowBandData] = useState(false); // Bands switched off by default
  const [showHeatmapData, setShowHeatmapData] = useState(true); // Heatmap remains on by default
  const [showLegends, setShowLegends] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth > 768; // Show legends by default on desktop, hide on mobile
    }
    return true; // Default to true if window is undefined
  });

  // **Handle Pair Selection from Dropdown**
  const handlePairSelect = (selectedPair) => {
    setSearchInput(selectedPair);
    navigate(`/chart/${selectedPair}`); // Navigate to the new URL with the selected pair
    // React will handle the update via useEffect
  };

  // **Resize Chart on Window Resize**
  const resizeChart = () => {
    if (chartRef.current && chartContainerRef.current) {
      chartRef.current.applyOptions({
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
      });
    }
  };

  // **Timestamp Parsing Function**
  const parseTimestamp = (timestampInput) => {
    if (timestampInput === null || timestampInput === undefined) {
      console.error('Timestamp is missing:', timestampInput);
      return null;
    }

    if (typeof timestampInput === 'number') {
      // If the timestamp is already a number (Unix timestamp in seconds), return it
      return timestampInput;
    }

    // Try parsing ISO format
    let timestamp = Date.parse(timestampInput);
    if (isNaN(timestamp)) {
      // Try replacing space with 'T' for ISO format
      timestamp = Date.parse(timestampInput.replace(' ', 'T'));
    }
    if (isNaN(timestamp)) {
      console.error('Invalid date:', timestampInput);
      return null;
    }
    return Math.floor(timestamp / 1000);
  };

  // **Color Interpolation Utility**
  const interpolateColor = (color1, color2, factor) => {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  };

  // **Define Heatmap Color Stops Adjusted for Counts up to 20**
  const colorStops = [
    { value: 0, color: [50, 50, 50] },       // Dark Gray
    { value: 4, color: [255, 105, 180] },    // Hot Pink
    { value: 8, color: [255, 165, 0] },      // Orange
    { value: 10, color: [255, 215, 0] },     // Gold
    { value: 12, color: [0, 255, 127] },     // Spring Green
    { value: 14, color: [0, 191, 255] },     // Deep Sky Blue
    { value: 16, color: [138, 43, 226] },    // Blue Violet
    { value: 18, color: [75, 0, 130] },      // Indigo
  ];

  // **Function to Get Heatmap Color Based on Amount with Linear Scaling**
  const getHeatmapColor = (amount) => {
    const maxAmount = maxHeatmapAmountRef.current;
    if (maxAmount === 0) return `rgba(${colorStops[0].color.join(',')}, 0.2)`;

    // **Apply linear scaling**
    const ratio = Math.min(amount / maxAmount, 1); // Normalize ratio to 0-1

    // **Map ratio to colorStops range**
    const scaledValue = ratio * (colorStops[colorStops.length - 1].value);
    let lowerStop = colorStops[0];
    let upperStop = colorStops[colorStops.length - 1];

    for (let i = 0; i < colorStops.length - 1; i++) {
      if (scaledValue >= colorStops[i].value && scaledValue <= colorStops[i + 1].value) {
        lowerStop = colorStops[i];
        upperStop = colorStops[i + 1];
        break;
      }
    }

    const range = upperStop.value - lowerStop.value;
    const factor = range === 0 ? 0 : (scaledValue - lowerStop.value) / range;
    const interpolatedColor = interpolateColor(lowerStop.color, upperStop.color, factor);

    const alpha = 1; // Adjust transparency to make OHLC data more visible
    return `rgba(${interpolatedColor.join(',')}, ${alpha})`;
  };

  // **Heatmap Shader Function**
  const cellShader = (amount) => {
    return getHeatmapColor(amount);
  };

  // **Process Heatmap Data by Merging with Existing Data**
  const processHeatmapData = (incomingHeatmapData, previousHeatmapData) => {
    // Validate incoming data structure
    if (!Array.isArray(incomingHeatmapData)) {
      console.error('Invalid heatmap data format:', incomingHeatmapData);
      return previousHeatmapData; // Return previous data if invalid
    }

    // **Check if 'incomingHeatmapData' has 'time' as a number**
    let transformedData;
    if (incomingHeatmapData.length > 0 && typeof incomingHeatmapData[0].time === 'number') {
      // Data is already transformed
      transformedData = incomingHeatmapData;
    } else {
      // Transform the incoming data to match HeatMapData format
      transformedData = incomingHeatmapData.map((entry) => {
        let timestamp = parseTimestamp(entry.time); // Convert to UNIX timestamp in seconds
        if (timestamp) {
          timestamp += 2 * 60 * 60; // **Add Two Hours Here**
        }
        const cells = entry.cells.map((cell) => ({
          low: parseFloat(cell.price_bin_start),
          high: parseFloat(cell.price_bin_end),
          amount: parseFloat(cell.count),
        }));
        return { time: timestamp, cells };
      })
      .filter(entry => typeof entry.time === 'number'); // **Filter out invalid entries**
    }

    // **Limit the number of data points to avoid large datasets**
    const maxDataPoints = 1000; // Adjust this value as needed
    let combinedData = [...previousHeatmapData, ...transformedData];
    if (combinedData.length > maxDataPoints) {
      combinedData = combinedData.slice(combinedData.length - maxDataPoints);
    }

    // **Calculate new maxAmount based on combined data and cap at 20**
    let newMaxAmount = Math.max(
      ...combinedData.flatMap(entry => entry.cells.map(cell => cell.amount)),
      maxHeatmapAmountRef.current
    );
    newMaxAmount = Math.min(newMaxAmount, 20); // **Cap at 20**
    maxHeatmapAmountRef.current = newMaxAmount;

    return combinedData;
  };

  // **Handle Incoming Heatmap Data**
  const handleHeatmapData = (incomingHeatmapData) => {
    console.log('Processing incoming heatmap data:', incomingHeatmapData);
    setHeatmapData((prevHeatmapData) => {
      const mergedHeatmapData = processHeatmapData(incomingHeatmapData, prevHeatmapData);

      // **Update HeatMapSeries**
      if (heatMapSeriesRef.current) {
        heatMapSeriesRef.current.setData(mergedHeatmapData); // Replace existing data
      }

      return mergedHeatmapData; // Update state with merged data
    });
  };

  // **Establish WebSocket Connection with Token**
  const createWebSocket = async () => {
    if (!currentUser) {
      console.error('No authenticated user found.');
      return;
    }

    try {
      // Obtain the Firebase ID token
      const token = await currentUser.getIdToken(/* forceRefresh */ true);

      // Establish WebSocket connection with pair and token as query parameters
      const ws = new WebSocket(
        `wss://dotgenerate-backend.azurewebsites.net/ws?pair=${pair || 'BTCUSDT'}&token=${token}`
      );

      wsRef.current = ws; // Assign to ref

      ws.onopen = () => {
        console.log(`WebSocket connection established for pair: ${pair || 'BTCUSDT'}.`);
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('WebSocket message received:', data);

        if (data.ev === 'XAS') {
          // Handle real-time candle data as before
          handleRealTimeCandleData(data);
        } else {
          // Handle bandData and heatmapData independently
          if (data.bandData) {
            handleBandData(data.bandData);
          }
          if (data.heatmapData) {
            handleHeatmapData(data.heatmapData);
          }
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        console.log(`WebSocket for pair ${pair || 'BTCUSDT'} closed.`);
        // Only attempt to reconnect if the component is still mounted and the pair hasn't changed
        if (wsRef.current === ws) { // Ensure it's the same WebSocket
          console.log('Attempting to reconnect WebSocket in 5 seconds...');
          setTimeout(() => createWebSocket(), 5000);
        }
      };
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
      // Optional: Implement additional error handling or user notifications here
    }
  };

  // **Handle Real-Time Candle Data**
  const handleRealTimeCandleData = (data) => {
    // Existing logic to handle candle data
    console.log('Processing real-time candle data:', data);

    const updatedCandle = {
      time: Math.floor(data.s) + 2 * 60 * 60, // **Add Two Hours Here**
      open: data.o,
      high: data.h,
      low: data.l,
      close: data.c,
    };

    console.log('Updated Candle Data:', updatedCandle);

    if (lastCandleRef.current && lastCandleRef.current.time) {
      const lastCandleTime = new Date(lastCandleRef.current.time * 1000);
      const updatedCandleTime = new Date(updatedCandle.time * 1000);

      if (
        lastCandleTime.getUTCMinutes() === updatedCandleTime.getUTCMinutes() &&
        lastCandleTime.getUTCHours() === updatedCandleTime.getUTCHours()
      ) {
        console.log('Updating existing candle');
        lastCandleRef.current = {
          ...lastCandleRef.current,
          high: Math.max(lastCandleRef.current.high, updatedCandle.high),
          low: Math.min(lastCandleRef.current.low, updatedCandle.low),
          close: updatedCandle.close,
        };
        if (candleSeriesRef.current) {
          console.log('Updating candle on chart:', lastCandleRef.current);
          candleSeriesRef.current.update(lastCandleRef.current);
        } else {
          console.error('candleSeriesRef.current is null when updating candle');
        }
      } else {
        console.log('Adding new candle');
        if (candleSeriesRef.current) {
          console.log('Adding new candle to chart:', updatedCandle);
          candleSeriesRef.current.update(updatedCandle);
        } else {
          console.error('candleSeriesRef.current is null when adding new candle');
        }
        lastCandleRef.current = updatedCandle;
      }
    } else {
      console.log('Adding first candle after historical data');
      lastCandleRef.current = updatedCandle;
      if (candleSeriesRef.current) {
        console.log('Adding first candle to chart:', updatedCandle);
        candleSeriesRef.current.update(updatedCandle);
      } else {
        console.error('candleSeriesRef.current is null when adding first candle');
      }
    }
    lastCandleUpdateRef.current = Date.now(); // Update the last candle update time

    // Process any pending band data updates
    processBandDataQueue();
  };

  // **Handle Incoming Band Data**
  const handleBandData = (bandData) => {
    // Existing logic to handle band data
    console.log('Processing band data:', bandData);

    const {
      shifted_q1_up,
      shifted_q3_up,
      shifted_q1_down,
      shifted_q3_down,
      shifted_lower_bound_down,
      shifted_upper_bound_up,
      smoothed_midline,
      band_timestamp,
      midline_timestamp,
    } = bandData;

    const bandTime = parseTimestamp(band_timestamp);
    const midlineTime = parseTimestamp(midline_timestamp);

    if (!bandTime || !midlineTime) {
      console.error('Invalid band or midline timestamp');
      return;
    }

    const updatedBandDataLightSalmon = {
      time: bandTime,
      high: parseFloat(shifted_q3_up),
      low: parseFloat(shifted_q1_up),
      close: (parseFloat(shifted_q3_up) + parseFloat(shifted_q1_up)) / 2,
    };

    const updatedBandDataCyan = {
      time: bandTime,
      high: parseFloat(shifted_q3_down),
      low: parseFloat(shifted_q1_down),
      close: (parseFloat(shifted_q3_down) + parseFloat(shifted_q1_down)) / 2,
    };

    const updatedBandDataTeal = {
      time: bandTime,
      high: parseFloat(shifted_q1_down),
      low: parseFloat(shifted_lower_bound_down),
      close: (parseFloat(shifted_q1_down) + parseFloat(shifted_lower_bound_down)) / 2,
    };

    const updatedBandDataUpper = {
      time: bandTime,
      high: parseFloat(shifted_upper_bound_up),
      low: parseFloat(shifted_q3_up),
      close: (parseFloat(shifted_upper_bound_up) + parseFloat(shifted_q3_up)) / 2,
    };

    const updatedMidline = {
      time: midlineTime,
      value: parseFloat(smoothed_midline),
    };

    bandDataQueue.current.push({
      updatedBandDataLightSalmon,
      updatedBandDataCyan,
      updatedBandDataTeal,
      updatedBandDataUpper,
      updatedMidline,
    });

    // Process band data queue immediately if the last candle was updated recently
    processBandDataQueue();
  };

  // **Process Band Data Queue**
  const processBandDataQueue = () => {
    const now = Date.now();
    const lastCandleUpdateTime = lastCandleUpdateRef.current || 0;

    if (now - lastCandleUpdateTime <= 5000) {
      // Last candle was updated within the last 5 seconds
      while (bandDataQueue.current.length > 0) {
        const bandData = bandDataQueue.current.shift();
        updateBandSeries(bandData);
      }
    } else {
      // Wait for 5 seconds before updating band data
      setTimeout(() => {
        if (bandDataQueue.current.length > 0) {
          const bandData = bandDataQueue.current.shift();
          updateBandSeries(bandData);
        }
      }, 5000);
    }
  };

  // **Update Band Series with New Data**
  const updateBandSeries = (bandData) => {
    const {
      updatedBandDataLightSalmon,
      updatedBandDataCyan,
      updatedBandDataTeal,
      updatedBandDataUpper,
      updatedMidline,
    } = bandData;

    if (customSeriesRefLightSalmon.current) {
      customSeriesRefLightSalmon.current.update(updatedBandDataLightSalmon);
    } else {
      console.error('customSeriesRefLightSalmon.current is null');
    }

    if (customSeriesRefCyan.current) {
      customSeriesRefCyan.current.update(updatedBandDataCyan);
    } else {
      console.error('customSeriesRefCyan.current is null');
    }

    if (customSeriesRefTeal.current) {
      customSeriesRefTeal.current.update(updatedBandDataTeal);
    } else {
      console.error('customSeriesRefTeal.current is null');
    }

    if (customSeriesRefUpper.current) {
      customSeriesRefUpper.current.update(updatedBandDataUpper);
    } else {
      console.error('customSeriesRefUpper.current is null');
    }

    if (smoothedMidlineSeriesRef.current) {
      smoothedMidlineSeriesRef.current.update(updatedMidline);
    } else {
      console.error('smoothedMidlineSeriesRef.current is null');
    }
  };

  // **Retry Fetch Function with Exponential Backoff**
  const retryFetch = async (fetchFunction, delay = 8000, maxAttempts = 5) => {
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        await fetchFunction();
        return;
      } catch (error) {
        console.error(`Fetch attempt ${attempt} failed:`, error);
        if (attempt === maxAttempts) {
          console.error('Max fetch attempts reached.');
          throw error;
        }
        await new Promise((res) => setTimeout(res, delay));
      }
    }
  };

  // **Initialize Chart and Fetch All Data**
  useEffect(() => {
    const initializeChartAndData = async () => {
      setIsLoading(true); // **Set loading state to true when initializing data**
      try {
        // Fetch OHLC Data
        await retryFetch(fetchOHLCData);
        // Fetch Band Data and Smoothed Midline Data concurrently
        await Promise.all([retryFetch(fetchBandData), retryFetch(fetchSmoothedMidlineData)]);
        // Fetch Heatmap Data
        await retryFetch(fetchHeatmapData);
        // Now that all data is fetched, set isLoading to false
        setIsLoading(false);
      } catch (error) {
        console.error('Error initializing chart and data:', error);
        setIsLoading(false); // **Ensure loading state is turned off even if there's an error**
      }
    };

    // Initialize Chart and Data
    if (currentUser) {
      initializeChartAndData();
      logEvent('page_view', { page_path: 'ProductPage' }); // Log page view when the component is mounted
    } else {
      console.error('User is not authenticated. Redirecting to login.');
      navigate('/login');
    }

    window.addEventListener('resize', resizeChart);

    return () => {
      // **Cleanup: Remove chart and reset data**
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }

      // **Reset data references**
      historicalBandData.current = {
        lightSalmon: [],
        cyan: [],
        lower: [],
        upper: [],
      };
      setHeatmapData([]);
      lastCandleRef.current = null;
      lastCandleUpdateRef.current = null;
      bandDataQueue.current = [];

      // **Reset series refs**
      customSeriesRefLightSalmon.current = null;
      customSeriesRefCyan.current = null;
      customSeriesRefTeal.current = null;
      customSeriesRefUpper.current = null;
      smoothedMidlineSeriesRef.current = null;
      heatMapSeriesRef.current = null;

      // **Remove resize listener**
      window.removeEventListener('resize', resizeChart);
    };
  }, [pair, currentUser, navigate]); // **Dependencies include 'pair' to handle trading pair changes**

  // **WebSocket Management Effect**
  useEffect(() => {
    if (!currentUser) return;

    // Establish WebSocket Connection
    createWebSocket();

    return () => {
      // **Cleanup: Close WebSocket when pair changes or component unmounts**
      if (wsRef.current) {
        console.log(`Closing WebSocket for pair: ${pair || 'BTCUSDT'}`);
        wsRef.current.close();
        wsRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pair, currentUser]); // **Re-run when 'pair' or 'currentUser' changes **/

  // **Fetch OHLC Data Function**
  const fetchOHLCData = async () => {
    try {
      // Use the `api` instance to include Authorization header automatically
      const response = await api.get(`ohlc?pair=${pair || 'BTCUSDT'}`); // No need to prepend the base URL

      console.log('Raw OHLC Data:', response.data);

      const parsedData = Papa.parse(response.data, { header: true });
      console.log('Parsed OHLC Data:', parsedData.data);

      const candleSeriesData = parsedData.data
        .slice(-30000)
        .map((row) => {
          const time = parseTimestamp(row.timestamp);
          if (!time) return null;
          return {
            time,
            open: parseFloat(row.open),
            high: parseFloat(row.high),
            low: parseFloat(row.low),
            close: parseFloat(row.close),
          };
        })
        .filter((item) => item !== null);

      console.log('Candle Series Data:', candleSeriesData);

      const uniqueCandleSeriesData = Array.from(
        new Map(candleSeriesData.map((item) => [item.time, item])).values()
      );
      uniqueCandleSeriesData.sort((a, b) => a.time - b.time);

      if (chartContainerRef.current) {
        // **Remove existing chart before creating a new one**
        if (chartRef.current) {
          chartRef.current.remove();
          chartRef.current = null;
        }

        // **Create a new chart instance**
        chartRef.current = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
          layout: {
            background: {
              color: '#f5f5f5', // Light background color for a modern look
            },
            textColor: '#333333',
          },
          grid: {
            vertLines: {
              color: '#e0e0e0',
            },
            horzLines: {
              color: '#e0e0e0',
            },
          },
          timeScale: {
            timeVisible: true,
            secondsVisible: false,
            tickLabelFontSize: 14,
            tickLabelColor: '#333333',
          },
          priceScale: {
            tickLabelFontSize: 14,
            tickLabelColor: '#333333',
            priceFormat: {
              type: 'price',
              precision: pair === 'DOGEUSDT' ? 5 : 2, // Ensure precision is set to 5 for DOGEUSDT
              minMove: pair === 'DOGEUSDT' ? 0.00001 : 0.01, // Set the minimum move accordingly
            },
          },
        });

        // **Initialize HeatMapSeries before Candlestick Series**
        const customHeatMapSeries = new HeatMapSeries();
        heatMapSeriesRef.current = chartRef.current.addCustomSeries(customHeatMapSeries, {
          cellShader,
          cellBorderWidth: 0,
          cellBorderColor: 'transparent',
        });

        // **Set initial visibility based on state**
        heatMapSeriesRef.current.applyOptions({ visible: showHeatmapData });

        // **Set initial heatmap data**
        heatMapSeriesRef.current.setData(heatmapData); // Use heatmapData state

        // **Initialize Candlestick Series After Heatmap Series**
        candleSeriesRef.current = chartRef.current.addCandlestickSeries({
          upColor: '#39FF14', // Neon Green
          downColor: '#FF073A', // Neon Red
          borderUpColor: '#4a4a4a', // Dark Gray border for up candles
          borderDownColor: '#4a4a4a', // Dark Gray border for down candles
          wickUpColor: '#39FF14', // Neon Green wick
          wickDownColor: '#FF073A', // Neon Red wick
          priceFormat: {
            type: 'price',
            precision: pair === 'DOGEUSDT' ? 5 : 2, // Ensure precision is set to 5 for DOGEUSDT
            minMove: pair === 'DOGEUSDT' ? 0.00001 : 0.01, // Set the minimum move accordingly
          },
        });

        candleSeriesRef.current.setData(uniqueCandleSeriesData);
        lastCandleRef.current = uniqueCandleSeriesData[uniqueCandleSeriesData.length - 1];
      }
    } catch (error) {
      console.error('Error fetching OHLC data:', error);
      throw error;
    }
  };

  // **Fetch Band Data Function**
  const fetchBandData = async () => {
    try {
      // Use the `api` instance to include Authorization header automatically
      const response = await api.get(`bands?pair=${pair || 'BTCUSDT'}`);

      console.log('Raw Band Data:', response.data);

      const parsedData = Papa.parse(response.data, { header: true });
      console.log('Parsed Band Data:', parsedData.data);

      const bandData = parsedData.data.slice(-30000);

      const bandDataLightSalmon = bandData
        .map((row) => {
          const time = parseTimestamp(row.timestamp);
          if (!time || row.shifted_q1_up === 'None' || row.shifted_q3_up === 'None') return null;
          return {
            time,
            high: parseFloat(row.shifted_q3_up),
            low: parseFloat(row.shifted_q1_up),
            close: (parseFloat(row.shifted_q3_up) + parseFloat(row.shifted_q1_up)) / 2,
          };
        })
        .filter((item) => item !== null);

      historicalBandData.current.lightSalmon = Array.from(
        new Map(bandDataLightSalmon.map((item) => [item.time, item])).values()
      );
      historicalBandData.current.lightSalmon.sort((a, b) => a.time - b.time);

      const bandDataCyan = bandData
        .map((row) => {
          const time = parseTimestamp(row.timestamp);
          if (!time || row.shifted_q1_down === 'None' || row.shifted_q3_down === 'None') return null;
          return {
            time,
            high: parseFloat(row.shifted_q3_down),
            low: parseFloat(row.shifted_q1_down),
            close: (parseFloat(row.shifted_q3_down) + parseFloat(row.shifted_q1_down)) / 2,
          };
        })
        .filter((item) => item !== null);

      historicalBandData.current.cyan = Array.from(
        new Map(bandDataCyan.map((item) => [item.time, item])).values()
      );
      historicalBandData.current.cyan.sort((a, b) => a.time - b.time);

      const bandDataTeal = bandData
        .map((row) => {
          const time = parseTimestamp(row.timestamp);
          if (
            !time ||
            row.shifted_lower_bound_down === 'None' ||
            row.shifted_q1_down === 'None'
          )
            return null;
          return {
            time,
            high: parseFloat(row.shifted_q1_down),
            low: parseFloat(row.shifted_lower_bound_down),
            close: (parseFloat(row.shifted_q1_down) + parseFloat(row.shifted_lower_bound_down)) / 2,
          };
        })
        .filter((item) => item !== null);

      historicalBandData.current.lower = Array.from(
        new Map(bandDataTeal.map((item) => [item.time, item])).values()
      );
      historicalBandData.current.lower.sort((a, b) => a.time - b.time);

      const bandDataUpper = bandData
        .map((row) => {
          const time = parseTimestamp(row.timestamp);
          if (!time || row.shifted_upper_bound_up === 'None' || row.shifted_q3_up === 'None')
            return null;
          return {
            time,
            high: parseFloat(row.shifted_upper_bound_up),
            low: parseFloat(row.shifted_q3_up),
            close: (parseFloat(row.shifted_upper_bound_up) + parseFloat(row.shifted_q3_up)) / 2,
          };
        })
        .filter((item) => item !== null);

      historicalBandData.current.upper = Array.from(
        new Map(bandDataUpper.map((item) => [item.time, item])).values()
      );
      historicalBandData.current.upper.sort((a, b) => a.time - b.time);

      if (chartRef.current) {
        // **Initialize LightSalmon Series**
        const customSeriesViewLightSalmon = new HLCAreaSeries();
        customSeriesRefLightSalmon.current = chartRef.current.addCustomSeries(
          customSeriesViewLightSalmon,
          {
            highLineColor: '#FFA07A', // Light Salmon
            lowLineColor: '#FFA07A',
            closeLineColor: '#FFA07A',
            areaBottomColor: 'rgba(255, 160, 122, 0.5)',
            areaTopColor: 'rgba(255, 160, 122, 0.5)',
            highLineWidth: 2,
            lowLineWidth: 2,
            closeLineWidth: 2,
          }
        );

        customSeriesRefLightSalmon.current.setData(historicalBandData.current.lightSalmon);

        // **Initialize Cyan Series (Bearish Regime)**
        const customSeriesViewCyan = new HLCAreaSeries();
        customSeriesRefCyan.current = chartRef.current.addCustomSeries(
          customSeriesViewCyan,
          {
            highLineColor: '#00BFFF', // Deep Sky Blue (updated)
            lowLineColor: '#00BFFF',
            closeLineColor: '#00BFFF',
            areaBottomColor: 'rgba(0, 191, 255, 0.5)', // Cyan Blue
            areaTopColor: 'rgba(0, 191, 255, 0.5)',
            highLineWidth: 2,
            lowLineWidth: 2,
            closeLineWidth: 2,
          }
        );

        customSeriesRefCyan.current.setData(historicalBandData.current.cyan);

        // **Initialize Teal Series (Chop)**
        const customSeriesViewTeal = new HLCAreaSeries();
        customSeriesRefTeal.current = chartRef.current.addCustomSeries(
          customSeriesViewTeal,
          {
            highLineColor: '#A9A9A9', // Dark Gray
            lowLineColor: '#A9A9A9',
            closeLineColor: '#A9A9A9',
            areaBottomColor: 'rgba(0, 255, 204, 0.5)', // Neon Teal
            areaTopColor: 'rgba(0, 255, 204, 0.5)',
            highLineWidth: 2,
            lowLineWidth: 2,
            closeLineWidth: 2,
          }
        );

        customSeriesRefTeal.current.setData(historicalBandData.current.lower);

        // **Initialize Upper Series (Optional if needed)**
        const customSeriesViewUpper = new HLCAreaSeries();
        customSeriesRefUpper.current = chartRef.current.addCustomSeries(
          customSeriesViewUpper,
          {
            highLineColor: '#FF4500', // OrangeRed
            lowLineColor: '#FF4500',
            closeLineColor: 'rgba(255, 69, 0, 0.5)',
            areaBottomColor: 'rgba(255, 69, 0, 0.5)',
            areaTopColor: 'rgba(255, 69, 0, 0.5)',
            highLineWidth: 2,
            lowLineWidth: 2,
            closeLineWidth: 2,
          }
        );

        customSeriesRefUpper.current.setData(historicalBandData.current.upper);

        // **Apply initial visibility based on showBandData state**
        customSeriesRefLightSalmon.current.applyOptions({ visible: showBandData });
        customSeriesRefCyan.current.applyOptions({ visible: showBandData });
        customSeriesRefTeal.current.applyOptions({ visible: showBandData });
        customSeriesRefUpper.current.applyOptions({ visible: showBandData });

        if (smoothedMidlineSeriesRef.current) {
          smoothedMidlineSeriesRef.current.applyOptions({ visible: showBandData });
        }
      }
    } catch (error) {
      console.error('Error fetching band data:', error);
      throw error;
    }
  };

  // **Fetch Smoothed Midline Data Function**
  const fetchSmoothedMidlineData = async () => {
    try {
      // Use the `api` instance to include Authorization header automatically
      const response = await api.get(`ohlc?pair=${pair || 'BTCUSDT'}`); // Updated endpoint

      console.log('Raw Smoothed Midline Data:', response.data);

      const parsedData = Papa.parse(response.data, { header: true });
      console.log('Parsed Smoothed Midline Data:', parsedData.data);

      const smoothedMidlineData = parsedData.data
        .slice(-30000)
        .map((row) => {
          const time = parseTimestamp(row.timestamp);
          if (!time || !row.smoothed_midline) return null;
          return {
            time,
            value: parseFloat(row.smoothed_midline),
          };
        })
        .filter((item) => item !== null);

      if (chartRef.current) {
        if (!smoothedMidlineSeriesRef.current) {
          smoothedMidlineSeriesRef.current = chartRef.current.addLineSeries({
            color: '#0011ff', // Dark Blue for the smoothed midline
            lineWidth: 2,
          });
        }

        smoothedMidlineSeriesRef.current.setData(smoothedMidlineData);
        smoothedMidlineSeriesRef.current.applyOptions({ visible: showBandData }); // **Bind visibility to showBandData**
      }
    } catch (error) {
      console.error('Error fetching smoothed midline data:', error);
      throw error;
    }
  };

  // **Fetch Heatmap Data Function**
  const fetchHeatmapData = async () => {
    try {
      const response = await api.get(`heatmap?pair=${pair || 'BTCUSDT'}`);
      console.log('Raw Heatmap Data:', response.data);

      // **Assuming response.data is an array of heatmap entries**
      const transformedData = response.data.map((entry) => {
        let timestamp = parseTimestamp(entry.time); // Convert to UNIX timestamp in seconds
        if (timestamp) {
          timestamp += 2 * 60 * 60; // **Add Two Hours Here**
        }
        const cells = entry.cells.map((cell) => ({
          low: parseFloat(cell.price_bin_start),
          high: parseFloat(cell.price_bin_end),
          amount: parseFloat(cell.count),
        }));
        return { time: timestamp, cells };
      })
      .filter(entry => typeof entry.time === 'number'); // **Filter out invalid entries**

      // **Limit the number of data points to avoid large datasets**
      const maxDataPoints = 1000; // Adjust this value as needed
      let limitedData = transformedData;
      if (transformedData.length > maxDataPoints) {
        limitedData = transformedData.slice(transformedData.length - maxDataPoints);
      }

      // **Calculate new maxAmount based on limited data and cap at 20**
      let newMaxAmount = Math.max(
        ...limitedData.flatMap(entry => entry.cells.map(cell => cell.amount)),
        maxHeatmapAmountRef.current
      );
      newMaxAmount = Math.min(newMaxAmount, 20); // **Cap at 20**
      maxHeatmapAmountRef.current = newMaxAmount;

      // **Set data to HeatMapSeries**
      if (heatMapSeriesRef.current) {
        heatMapSeriesRef.current.setData(limitedData);
      }

      // **Set heatmapData state**
      setHeatmapData(limitedData);
    } catch (error) {
      console.error('Error fetching heatmap data:', error);
      throw error;
    }
  };

  // **Effect to Handle Visibility Toggling for Band Data**
  useEffect(() => {
    // Toggle Band Data Visibility
    if (customSeriesRefLightSalmon.current) {
      customSeriesRefLightSalmon.current.applyOptions({ visible: showBandData });
    }
    if (customSeriesRefCyan.current) {
      customSeriesRefCyan.current.applyOptions({ visible: showBandData });
    }
    if (customSeriesRefTeal.current) {
      customSeriesRefTeal.current.applyOptions({ visible: showBandData });
    }
    if (customSeriesRefUpper.current) {
      customSeriesRefUpper.current.applyOptions({ visible: showBandData });
    }
    if (smoothedMidlineSeriesRef.current) {
      smoothedMidlineSeriesRef.current.applyOptions({ visible: showBandData });
    }
  }, [showBandData]);

  // **Effect to Handle Visibility Toggling for Heatmap Data**
  useEffect(() => {
    if (heatMapSeriesRef.current) {
      heatMapSeriesRef.current.applyOptions({ visible: showHeatmapData });
    }
  }, [showHeatmapData]);

  // **Effect to Handle Window Resize for Legends Toggle**
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768 && showLegends) {
        setShowLegends(false); // Hide legends on mobile
      } else if (window.innerWidth > 768 && !showLegends) {
        setShowLegends(true); // Show legends on desktop
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showLegends]);

  return (
    <>
      {/* **Top Bar with Search, Navigation, and Legends Toggle Switch** */}
      <div className="top-bar">
        <div className="trading-pair">
          <h2 className="trading-pair-title">Trading Pair:</h2>
          <div className="search-bar">
            <select
              className="ticker-select-input"
              value={searchInput}
              onChange={(e) => handlePairSelect(e.target.value)}
            >
              {tradingPairs.map((p) => (
                <option key={p} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* **Legends Toggle Switch Below Existing Switches** */}
        <div className="switches-overlay">
          <div className="switches">
            <label className="switch-label" aria-label="Toggle Band Data">
              {showBandData ? (
                <FaToggleOn
                  className="toggle-icon"
                  onClick={() => setShowBandData(!showBandData)}
                  size={25}
                  color="#4caf50" // Green color when on
                />
              ) : (
                <FaToggleOff
                  className="toggle-icon"
                  onClick={() => setShowBandData(!showBandData)}
                  size={25}
                  color="#ccc" // Gray color when off
                />
              )}
              <span className="switch-text">Show Bands</span>
            </label>
            <label className="switch-label" aria-label="Toggle Heatmap Data">
              {showHeatmapData ? (
                <FaToggleOn
                  className="toggle-icon"
                  onClick={() => setShowHeatmapData(!showHeatmapData)}
                  size={25}
                  color="#2196f3" // Blue color when on
                />
              ) : (
                <FaToggleOff
                  className="toggle-icon"
                  onClick={() => setShowHeatmapData(!showHeatmapData)}
                  size={25}
                  color="#ccc" // Gray color when off
                />
              )}
              <span className="switch-text">Show Heatmap</span>
            </label>
            {/* **New Switch for Legends** */}
            <label className="switch-label" aria-label="Toggle Legends">
              {showLegends ? (
                <FaToggleOn
                  className="toggle-icon"
                  onClick={() => setShowLegends(!showLegends)}
                  size={25}
                  color="#2196f3" // Blue color when on
                />
              ) : (
                <FaToggleOff
                  className="toggle-icon"
                  onClick={() => setShowLegends(!showLegends)}
                  size={25}
                  color="#ccc" // Gray color when off
                />
              )}
              <span className="switch-text">Show Legends</span>
            </label>
          </div>
        </div>

        {/* **Navigation Link to Home with Light Styling on Right Side** */}
        <Link
          to="/"
          className="nav-item-chart"
          onClick={() => logEvent('navigation', { destination: 'home' })}
        >
          Home
        </Link>
      </div>

      {/* **Chart Container** */}
      <div className="chart-container" ref={chartContainerRef}>
        {isLoading && <div className="loading-overlay">Loading Chart...</div>}
      </div>

      {/* **Heatmap Legend Positioned Over the Chart** */}
      <div className={`heatmap-legend-overlay ${showLegends ? 'visible' : 'hidden'}`}>
        <h3>Probability Heatmap</h3>
        <div className="legend-gradient"></div>
        <div className="legend-labels">
          <span>Low</span>
          <span>High</span>
        </div>
      </div>

      {/* **Bands Legend Positioned Over the Chart** */}
      <div className={`bands-legend-overlay ${showLegends ? 'visible' : 'hidden'}`}>
        <h3>Bands Legend</h3>
        <div className="legend-items">
          <div className="legend-item">
            <span className="legend-color" style={{ backgroundColor: '#FFA07A' }}></span>
            <span>Bullish Regime</span>
          </div>
          <div className="legend-item">
            <span className="legend-color" style={{ backgroundColor: '#00BFFF' }}></span> {/* Changed to Cyan Blue */}
            <span>Bearish Regime</span>
          </div>
          <div className="legend-item">
            <span className="legend-color" style={{ backgroundColor: '#A9A9A9' }}></span>
            <span>Chop</span>
          </div>
        </div>
      </div>

      {/* **Generate Text Back to Original Position** */}
      <div className="generate-text">
        <p>.Generate()</p>
      </div>

      {/* **Disclaimer Section** */}
      <div className="disclaimer">
        <div className="centered-text">
          <p className="disclaimer-text">
            The orange and blue bands are AI generated and can vary in accuracy. All content on this
            site is for educational purposes only and does not constitute financial advice.{' '}
            <Link
              to="/legal-disclaimer"
              className="disclaimer-link"
              onClick={() => logEvent('navigation', { destination: 'legal_disclaimer' })}
            >
              Legal Disclaimer
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
