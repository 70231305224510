// src/services/api.js

import axios from 'axios';
import { getAuth } from 'firebase/auth';

// Create an Axios instance with the base URL including '/api'
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://dotgenerate-backend.azurewebsites.net/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to add the Firebase ID token to headers
api.interceptors.request.use(
  async (config) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        const token = await user.getIdToken(); // Removed forceRefresh for efficiency
        config.headers.Authorization = `Bearer ${token}`;
      } catch (tokenError) {
        console.error('Error fetching Firebase ID token:', tokenError);
        // Optionally, handle token fetching errors, e.g., redirect to login
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optional: Response interceptor to handle unauthorized responses globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access, e.g., redirect to login
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
