import React, { useEffect } from 'react';
import './LegalDisclaimerPage.css';
import { Link } from 'react-router-dom';
import { logEvent } from './analytics';

const LegalDisclaimerPage = () => {
  useEffect(() => {
    logEvent('PageView', 'LegalDisclaimerPage'); // Log page view when the component is mounted
  }, []);

  return (
    <div className="legal-disclaimer-page">
      <div className="navbar">
        <div className="navbar-brand">
          <Link to="/" className="navbar-brand-link">.Generate()</Link>
        </div>
        <div className="nav-links">
          <Link to="/" className="nav-item">RESOURCES</Link>
          <Link to="/" className="nav-item">LOG IN</Link>
          <Link to="/" className="nav-item">SIGN UP</Link>
          <Link to="/" className="nav-item">HOME</Link> {/* Updated from CONTACT to HOME */}
        </div>
      </div>
      <h1>Legal Disclaimer</h1>
      <div className="legal-disclaimer">
        <p>By accessing and using the trading signals, analysis, and observations provided by dotgenerate.com, you agree to the following terms and conditions:</p>
        <p><strong>1. Informational and Educational Purposes Only</strong></p>
        <p>The trading signals, analysis, and observations provided by dotgenerate.com are for informational and educational purposes only. They do not constitute investment advice. dotgenerate.com is not a financial advisor and does not provide personalized financial or investment advice. The information is based on the professional judgment of dotgenerate.com and may not be suitable for your particular circumstances.</p>
        <p><strong>2. No Guarantee of Accuracy or Completeness</strong></p>
        <p>The trading signals, analysis, and observations are based on dotgenerate.com's personal experience and research. They are not guaranteed to be accurate or complete. Financial markets are unpredictable, and past performance is not indicative of future results.</p>
        <p><strong>3. Assumption of Risk</strong></p>
        <p>By accessing and using the trading signals, analysis, and observations, you acknowledge that cryptocurrency and other financial trading are highly volatile and involve a high degree of risk. You agree to take full responsibility for any trades you execute and to bear all the risks associated with such trading. It is your responsibility to manage your risk properly, including setting stop-loss orders and limiting your exposure to the market.</p>
        <p><strong>4. No Liability for Losses</strong></p>
        <p>dotgenerate.com is not responsible for any losses that may occur as a result of using the trading signals, analysis, and observations. You agree not to hold dotgenerate.com liable for any trading losses you may incur. It is crucial to undertake your own analysis and to consult with a qualified financial advisor if necessary.</p>
        <p><strong>5. Modification and Termination of Services</strong></p>
        <p>dotgenerate.com reserves the right to modify or discontinue the trading signals, analysis, and observations at any time without prior notice. dotgenerate.com also reserves the right to terminate any user's access to these services at any time and for any reason.</p>
        <p><strong>6. Indemnification</strong></p>
        <p>By accessing and using the trading signals, analysis, and observations, you agree to indemnify and hold harmless dotgenerate.com from any and all claims, damages, losses, liabilities, costs, and expenses, including attorneys' fees, arising out of or in connection with your use of these services.</p>
        <p><strong>7. Compliance with EU and Global Regulations</strong></p>
        <p>This disclaimer is provided in compliance with applicable laws and regulations of the European Union and other jurisdictions worldwide. Users are responsible for ensuring that their actions comply with the laws and regulations of their respective countries.</p>
        <p><strong>8. No Warranty of Accuracy</strong></p>
        <p>The trading signals, analysis, and observations provided by dotgenerate.com do not come with any warranty or guarantee of accuracy. The performance of these signals may be affected by various factors, including market conditions, technological issues, and unforeseeable events. Users should not rely on these signals as a basis for making financial decisions without conducting their own thorough analysis.</p>
        <p><strong>9. Full Responsibility</strong></p>
        <p>You agree to take full responsibility for any trades you execute and to bear all risks associated with cryptocurrency and financial trading. You acknowledge that stop-loss orders may be triggered, which is a common part of trading. You agree not to hold dotgenerate.com responsible for any losses resulting from the use of trading signals, analysis, and observations.</p>
        <p><strong>10. Acknowledgment of Terms and Disclaimer</strong></p>
        <p>By using the trading signals, analysis, and observations provided by dotgenerate.com, you acknowledge that you have read and understood the terms and conditions and this disclaimer, and you agree to comply with them.</p>
      </div>
    </div>
  );
};

export default LegalDisclaimerPage;
