import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ContactPage.css';
import { logEvent } from './analytics';
import Navbar from './components/Navbar'; // Adjust the path based on your directory structure

const ContactPage = () => {
  useEffect(() => {
    logEvent('PageView', 'ContactPage'); // Log page view when the component is mounted
  }, []);

  return (
    <div className="contact-page">
      <Navbar />
      <h1>Contact Us</h1>
      <div className="contact-details">
        <p>Company Name: Milis Corporation, s.r.o.</p>
        <p>IČO: 26174669</p>
        <p>Address: Grafická 831/20, Smíchov, 150 00 Praha 5</p>
        <p>Email: <a href="mailto:contact@dotgenerate.com">contact@dotgenerate.com</a></p>
      </div>
    </div>
  );
};

export default ContactPage;
