// src/SubscribePage.js

import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from './services/api'; // Use the Axios instance from your services
import { useAuth } from './AuthContext';
import { getAuth } from 'firebase/auth';
import './SubscribePage.css'; // Import the CSS file for styling
import Navbar from './components/Navbar'; // Import the Navbar component

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_test_51PW13Q09yl57UYilKImTwYFBzdB8SzY4y0G5zvWgmkw8PPl8kQjWGaJvO27pmj89b3bT70EFFQPEGbyiiJsVp79u00xph6Raoi');

const SubscribePage = () => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const auth = getAuth();

  const handleSubscribe = async () => {
    setLoading(true);
    const stripe = await stripePromise;

    try {
      const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);

      const { data: session } = await api.post(
        '/create-checkout-session', // Ensure the endpoint is correct
        {
          email: currentUser.email, // Removed userId from the request body
        },
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error('Stripe checkout error:', error);
        alert(`Stripe Checkout Error: ${error.message}`);
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
      alert('Failed to create checkout session. Please try again.');
    }

    setLoading(false);
  };

  return (
    <>
      <Navbar /> {/* Include the Navbar here */}
      <div className="subscribe-page">
        <div className="subscribe-card">
          <h2 className="subscribe-title">Subscribe to Premium</h2>
          <p className="subscribe-description">
            Unlock exclusive features and gain access to premium content by subscribing to our premium plan.
          </p>
          <button
            className="subscribe-button"
            onClick={handleSubscribe}
            disabled={loading}
          >
            {loading ? (
              <div className="spinner"></div>
            ) : (
              'Subscribe Now'
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SubscribePage;
