// src/components/ResetPassword.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import Navbar from './Navbar';
import './ResetPassword.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Please check your inbox.');
      setError('');
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (error) {
      setError('Error sending reset email: ' + error.message);
      setMessage('');
    }
  };

  return (
    <div className="reset-password-page">
      <Navbar />
      <div className="reset-password-container">
        <div className="reset-password-box">
          <h2>Reset Password</h2>
          <form onSubmit={handleResetPassword}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <button type="submit">Send Reset Email</button>
          </form>
          {message && <p className="message">{message}</p>}
          {error && <p className="error">{error}</p>}
          <p>
            <Link to="/login">Remembered your password? Log in here</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
