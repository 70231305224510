// src/LoginPage.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from './firebase';
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import './LoginPage.css';
// Removed: import Navbar from './components/Navbar'; // Remove Navbar import if using Layout

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        // User is verified, proceed to dashboard
        navigate('/dashboard');
      } else {
        // User is not verified
        setMessage('Please verify your email address before logging in.');
        // Optionally, you can sign the user out
        await auth.signOut();
      }
    } catch (error) {
      // Handle different Firebase Auth errors
      switch (error.code) {
        case 'auth/user-not-found':
          setError('No user found with this email.');
          break;
        case 'auth/wrong-password':
          setError('Incorrect password.');
          break;
        case 'auth/invalid-email':
          setError('Invalid email address.');
          break;
        default:
          setError(error.message); // Handle other errors
      }
      setMessage('');
    }
  };

  const handleResendVerification = async () => {
    try {
      const user = auth.currentUser;
      if (user && !user.emailVerified) {
        await sendEmailVerification(user);
        setMessage('Verification email resent. Please check your inbox.');
        setError('');
      } else {
        setError('User is already verified or not logged in.');
      }
    } catch (error) {
      setError('Failed to resend verification email. Please try again.');
      setMessage('');
      console.error('Error resending verification email:', error);
    }
  };

  return (
    <div className="login-page">
      {/* Removed: <Navbar /> */} {/* Remove Navbar usage if using Layout */}

      <div className="login-container">
        <div className="login-box">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <div className="password-field">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              {/* Optionally, add show/hide password toggle here as well */}
            </div>
            <button type="submit">Login</button>
          </form>
          {message && <p className="message">{message}</p>}
          {error && <p className="error">{error}</p>}
          <p>
            <Link to="/sign-up">Don't have an account? Sign up here</Link>
          </p>
          <p>
            <Link to="/reset-password">Forgot Password?</Link>
          </p>
          {/* Show resend verification email button if user tried to log in without verifying email */}
          {message && (
            <div className="resend-verification">
              <p>If you did not receive the verification email, you can resend it.</p>
              <button onClick={handleResendVerification}>Resend Verification Email</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
